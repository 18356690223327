import { useContext, useEffect, useRef, useState } from "react";
import { SerialServiceContext } from ".";
import { log } from "node:console";
import BodyComponent from "./Components/bodyComponent";
import ToggleSwitch from "./Components/toggleSwitch";
import Loader from "./constants/loader";
type PanelDetails = {
  panelId: string;
  buttonCommand: string[];
};
type PanelSelectedDetails = {
  panelId: string;
  buttonCommand: string;
  type: string;
};

enum ButtonType {
  DimmerLight = "dimmer_light",
  Light = "light",
}

function App() {
  const context: any = useContext(SerialServiceContext);
  const serialService = useContext(SerialServiceContext);
  const [isConnected, setIsConnected] = useState(false);
  const [commands, setCommands] = useState<string[]>([]);
  const [configuredList, setConfiguredList] = useState<string[]>([]);
  const [parableList, setParableList] = useState<string[]>([]);
  const [selectedPanelId, setSelectedPanelId] = useState<string | null>(null);

  const [viewPanelButtons, setViewPanelButtons] =
    useState<PanelDetails | null>();
  const [storeTwoWayPanelButtons, setStoreTwoWayPanelButtons] =
    useState<PanelSelectedDetails | null>();
  const [sliderValues, setSliderValues] = useState<{ [key: string]: number }>(
    {}
  );
  const [buttonStates, setButtonStates] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [loadingStates, setLoadingStates] = useState(false);
  const [connectionLoadingStates, setConnectionLoadingStates] = useState(false);
  const [deviceloadingStates, setDeviceLoadingStates] = useState(false);
  const [getInfoMainDialog, setGetInfoMainDialog] = useState(false);

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [panelInfo, setPanelInfo] = useState<string | null>(null);
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [openTwoWayBinding, setOpenTwoWayBinding] = useState<boolean>(false);
  const [openTwoWayBindingData, setOpenTwoWayBindingData] =
    useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<string>("-1");
  const [isMacId, setIsMacId] = useState<string>("-1");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const sliderValueChanged = (buttonId: string, val: number) => {
    setSliderValues((prev) => ({
      ...prev,
      [buttonId]: val,
    }));
  };

  const parseButtonCommand = (cmd: string) => {
    const parts = cmd.split(",");
    return {
      number: parts[2],
      type: parts[3] as ButtonType,
      btnstatus: parts[4]?.trim() === "on",
      dimmevalue: parts[5],
    };
  };

  // function oncommand(text: string) {
  //   setCommands((command) => [...command, text]);
  // } //--- comment by GS
  useEffect(() => {
    if (viewPanelButtons) {
      viewPanelButtons.buttonCommand.forEach((cmd) => {
        const { number, type, dimmevalue, btnstatus } = parseButtonCommand(cmd);

        if (type === ButtonType.DimmerLight) {
          const dimmerValue = parseInt(dimmevalue, 10);
          setSliderValues((prev) => ({
            ...prev,
            [number]: dimmerValue,
          }));
        }
      });
    }
  }, [viewPanelButtons]);
  useEffect(() => {
    serialService.commandState = oncommand;
  }, [selectedPanelId]);
  // }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getConfiguredPanels();
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  function oncommand(text: string) {
    const connectionStatus = text.includes("status-true");
    {
      connectionStatus && setConnectionLoadingStates(false);
    }
    try {
      setCommands((command) => [...command, text]);
      const [action, data] = text.split(":");
      const macIds = data?.replace(/[<>]/g, "");

      setIsMacId(macIds);
      if (action === "getinfo") {
        const panelId = data.match(/<([^>]+)>/)?.[1];
        const buttonCommands = data
          .replace(/<|>/g, "")
          .split(",")
          .filter((cmd) => cmd.trim().length > 0);

        buttonCommands.forEach((cmd) => {
          const { number, type, btnstatus, dimmevalue } =
            parseButtonCommand(cmd);

          if (type === ButtonType.DimmerLight) {
            setSliderValues((prev) => ({
              ...prev,
              [number]: parseInt(dimmevalue, 10),
            }));
          }

          setButtonStates((prev) => ({
            ...prev,
            [number]: btnstatus,
          }));
        });

        setTimeout(() => {
          setLoadingStates(false);
        }, 1000);
      } else {
        const match = text.match(/<([^>]+)>/);
        if (!match) {
          console.warn("Invalid text format");
          return;
        }

        const data = match[1];
        const parts = data.split(",");

        setIsMacId(macIds);
        const type = parts[0];
        const index = parseInt(parts[4], 10);
        const status = parts[5];

        // if (parts[6] == "start") {
        //   setDeviceLoadingStates(true);
        // }
        // if (parts[6] == "end") {
        //   setDeviceLoadingStates(false);
        // }

        // if (parts[3] === ButtonType.DimmerLight && type !== "sendex") {
        //   setSliderValues((prev) => ({
        //     ...prev,
        //     [parts[2]]: parseInt(parts[5], 10),
        //   }));
        // }
        // if (type == "sendex") {
        //   console.log("GS--oncommand ---else");

        //   setButtonStates((prev) => {
        //     const newState = {
        //       ...prev,
        //       [index]: status === "on", // Update based on incoming status
        //     };
        //     return newState;
        //   });
        //   setSliderValues((prev) => ({
        //     ...prev,
        //     [index]: parseInt(parts[6]),
        //   }));
        // }
        if (parts[1] == selectedPanelId) {
          if (parts[3] === ButtonType.DimmerLight && type !== "sendex") {
            setSliderValues((prev) => ({
              ...prev,
              [parts[2]]: parseInt(parts[5], 10),
            }));
          }
          if (type == "sendex") {
            setButtonStates((prev) => {
              const newState = {
                ...prev,
                [index]: status === "on", // Update based on incoming status
              };
              return newState;
            });
            setSliderValues((prev) => ({
              ...prev,
              [index]: parseInt(parts[6]),
            }));
          }
        }
        console.log("onclicK - LOADER false? ");
        setDeviceLoadingStates(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const isChrome = "chrome" in window;

  if (!isChrome) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen m-10">
        <h1 className="text-3xl">Unsupported Browser</h1>
      </div>
    );
  }

  const getConfiguredPanels = async () => {
    setConnectionLoadingStates(true);
    await serialService.write("getallpanels\r\n");
  };
  const localFunction = async () => {
    await serialService.write("get-status");
    await serialService.write("getallpanels\r\n");
  };
  //---toggle start
  const handleButtonClick = async (
    macId: string,
    number: string,
    sliderValue?: number,
    state?: "on" | "off"
  ) => {
    setLoadingStates(true);
    try {
      setIsMacId(macId);
      let updatedSliderValue = sliderValue;
      if (state === "on") {
        const buttonCommand = viewPanelButtons?.buttonCommand.find((cmd) => {
          const { number: cmdNumber } = parseButtonCommand(cmd);
          return cmdNumber === number;
        });

        if (buttonCommand) {
          const { type } = parseButtonCommand(buttonCommand);
          if (type === ButtonType.DimmerLight) {
            updatedSliderValue = sliderValue == 0 ? 60 : sliderValue;
            setSliderValues((prev: any) => ({
              ...prev,
              [number]: updatedSliderValue,
            }));
          }
        }
      }

      if (state === "off") {
        updatedSliderValue = 0;
        setSliderValues((prev) => ({
          ...prev,
          [number]: 0,
        }));
      }

      setButtonStates((prev) => ({
        ...prev,
        [number]: state === "on",
      }));

      await serialService.write(
        `sendrf,ucast,${macId},<req,${state},${number},${updatedSliderValue},null>\r\n`
      );
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingStates(false);
    }
  };
  //--end toggle button
  const handleGetInfoTwoWayBinding = async (panel: string) => {
    setViewPanelButtons(null);
    setOpenTwoWayBindingData(true);
    await serialService.write(`getinfo:<${panel}>`);
  };
  const handleCloseTwoWayBinding = () => {
    setOpenTwoWayBinding(false);
    // setSelectedPanelId(null);
  };

  const handleDropdownMenu = (dropdownData: boolean, index: string) => {
    setIsDropdownOpen(dropdownData);
    setSelectedIndex(index);
  };
  const handleTwoWayBindingDataClose = () => {
    //----last
    setOpenTwoWayBindingData(false);
    setViewPanelButtons(null);
    setGetInfoMainDialog(false);
    // setSelectedPanelId(null);
  };
  const handleTwoWayBinding = (id: string, index: string, type: string) => {
    setOpenTwoWayBinding(true);
    setIsMacId(id);
    // setViewPanelButtons(null);

    setStoreTwoWayPanelButtons(() => {
      let obj = {
        panelId: id,
        buttonCommand: index,
        type: type,
      };

      return obj;
    });
    setIsDropdownOpen(false);
  };
  const handleWithTwoWayBinding = (panelId: string, number: string) => {
    const prePanelId = storeTwoWayPanelButtons?.panelId || "defaultPrePanelId";
    const preButtonNumber =
      storeTwoWayPanelButtons?.buttonCommand || "defaultPreButtonNumber";

    const command = `binding:<${panelId}:${number},${prePanelId}:${preButtonNumber}>`;
    serialService.write(command);
    setOpenTwoWayBindingData(false);
    setOpenTwoWayBinding(false);
    setGetInfoMainDialog(false);
    alert(
      `Success! Binding with ${prePanelId}:${preButtonNumber} to  ${panelId}:${number}.`
    );
  };
  const handleClosePanelInformationModel = () => {
    setIsInfoModalOpen(false);
    setSelectedPanelId(null);
  };
  const handleClosePanelButtonModel = () => {
    setViewPanelButtons(null);
    setGetInfoMainDialog(false);
    setSelectedPanelId(null);
    setButtonStates({});
  };
  const handleGetInfoPanel = async (panelId: string) => {
    // setIsDropdownOpen()
    setDeviceLoadingStates(true);
    setSelectedPanelId(panelId);
    setIsDropdownOpen(false);
    setGetInfoMainDialog(true);
    await serialService.write(`getinfo:<${panelId}>`);
  };

  return (
    <div className="flex relative flex-col items-center justify-start min-h-screen m-0">
      {!openTwoWayBinding && getInfoMainDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-2 z-50">
          <div className="bg-white relative p-6 rounded-lg shadow-lg w-96 min-h-[70%]">
            {isDropdownOpen && (
              <button
                className=" flex h-full z-40 w-full absolute"
                onClick={() => setIsDropdownOpen(false)}
              />
            )}
            <div className="border-b mt-10">
              <h2 className="text-xl font-semibold mb-2  pb-2">
                Panel Buttons
              </h2>
            </div>

            {isInfoModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
                  <h2 className="text-xl font-semibold mb-4">
                    Panel Information
                  </h2>
                  <div className="text-gray-800 whitespace-pre-wrap">
                    {panelInfo || "Loading..."}
                  </div>
                  <button
                    className="absolute top-2 right-2 text-red-500 font-bold text-lg"
                    onClick={() => handleClosePanelInformationModel()}
                  >
                    ×
                  </button>
                </div>
              </div>
            )}
            {deviceloadingStates ? (
              <Loader position="absolute top-[50%]  -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" />
            ) : (
              viewPanelButtons && (
                <div>
                  <div className="mb-0">
                    <strong className="block text-gray-700 mb-0">
                      Panel ID:
                    </strong>
                    <div className="text-gray-800">
                      {viewPanelButtons.panelId}
                    </div>
                  </div>

                  <div className="block relative overflow-y-auto  max-h-[450px] ">
                    <ul className="space-y-2">
                      {viewPanelButtons.buttonCommand.map((cmd, index) => {
                        const { number, type, btnstatus } =
                          parseButtonCommand(cmd);
                        if (!Object.values(ButtonType).includes(type)) {
                          return null;
                        }

                        // setButtonStates([number],btnstatus)\
                        return (
                          <div key={number} className=" border-b pb-2">
                            <li className="flex flex-col gap-2">
                              <div className="flex justify-between items-center">
                                <span className="text-gray-600 font-medium">
                                  Button {number}
                                </span>
                                <div className="flex items-center gap-2">
                                  <ToggleSwitch
                                    buttonId={number}
                                    disabled={loadingStates}
                                    macId={viewPanelButtons.panelId}
                                    sliderValue={sliderValues[number] || 0}
                                    isOn={buttonStates[number] ?? btnstatus} // comment by gs
                                    // isOn={btnstatus}
                                    onToggle={(newState: any) =>
                                      handleButtonClick(
                                        viewPanelButtons.panelId,
                                        number,
                                        sliderValues[number],
                                        newState ? "on" : "off"
                                      )
                                    }
                                  />

                                  <button
                                    className=" pl-6 pr-6"
                                    onClick={() =>
                                      handleDropdownMenu(
                                        !isDropdownOpen,
                                        number
                                      )
                                    }
                                  >
                                    <ul className="-space-y-8">
                                      <li>•</li>
                                      <li>•</li>
                                      <li>•</li>
                                    </ul>
                                  </button>
                                  {selectedIndex === number &&
                                    isDropdownOpen && (
                                      <div
                                        ref={dropdownRef}
                                        className="absolute right-4 flex z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-22"
                                      >
                                        <ul className=" text-sm text-gray-700">
                                          <li className="block px-4 py-2 hover:bg-gray-100">
                                            <button
                                              onClick={() =>
                                                handleTwoWayBinding(
                                                  viewPanelButtons.panelId,
                                                  number,
                                                  type
                                                )
                                              }
                                            >
                                              Two way binding
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                </div>
                              </div>
                              {type === "dimmer_light" && (
                                <RangeSlider
                                  buttonId={number}
                                  macId={viewPanelButtons.panelId}
                                  value={sliderValues[number] || 0}
                                  onChange={(value: number) =>
                                    sliderValueChanged(number, value)
                                  }
                                  handleButtonClick={handleButtonClick}
                                  classes="w-full"
                                />
                              )}
                            </li>
                          </div>
                        );
                      })}
                    </ul>
                    {loadingStates && <Loader />}
                  </div>
                </div>
              )
            )}
            <button
              className="absolute top-1 right-2 text-red-500 font-bold text-lg"
              onClick={() => handleClosePanelButtonModel()}
            >
              ×
            </button>
          </div>
        </div>
      )}
      {/* //dailog - model */}

      {openTwoWayBinding && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-2 z-50">
          <div className="bg-white relative p-6 rounded-lg shadow-lg w-96 ">
            <div className="border-b mt-10">
              <h2 className="text-xl font-semibold mb-2  pb-2">
                For two way find Panels
              </h2>
            </div>
            {configuredList.map((panel) => {
              console.log(
                "panel !== selectedPanelId",
                panel,
                "selectedPanelId",
                selectedPanelId
              );

              if (panel !== selectedPanelId) {
                return (
                  <div
                    className="flex flex-row gap-6 items-center my-2"
                    key={panel}
                  >
                    <div>{panel}</div>
                    <button
                      className="px-2 py-1 bg-emerald-300 text-black font-medium rounded-lg text-xs"
                      onClick={() => {
                        handleGetInfoTwoWayBinding(panel);
                        //serialService.write(`getinfo:<${panel}>`);
                      }}
                    >
                      GETINFO
                    </button>
                  </div>
                );
              }
            })}
            {/* //data---- */}
            <button
              className="absolute top-1 right-2 text-red-500 font-bold text-lg"
              onClick={() => handleCloseTwoWayBinding()} //setOpenTwoWayBinding(false)
            >
              ×
            </button>
          </div>
        </div>
      )}
      {openTwoWayBindingData && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-2 z-50">
          <div className="bg-white relative p-6 rounded-lg shadow-lg w-96 min-h-[70%] ">
            {/* <div className="border-b mt-10">
              <h2 className="text-xl font-semibold mb-2  pb-2">
                Panel Buttons
              </h2>
            </div> */}

            {deviceloadingStates ? (
              <Loader position="absolute top-[50%]  -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" />
            ) : viewPanelButtons ? (
              <div>
                <div className="border-b mt-10">
                  <h2 className="text-xl font-semibold mb-2  pb-2">
                    Panel Buttons
                  </h2>
                </div>
                {viewPanelButtons?.panelId && (
                  <div className="mb-0">
                    <strong className="block text-gray-700 mb-0">
                      Panel ID:
                    </strong>
                    <div className="text-gray-800">
                      {viewPanelButtons?.panelId}
                    </div>
                  </div>
                )}
                <div className="mt-3">
                  {viewPanelButtons?.buttonCommand.map((cmd, index) => {
                    const { number, type, btnstatus } = parseButtonCommand(cmd);
                    if (!Object.values(ButtonType).includes(type)) {
                      return null;
                    }

                    // setButtonStates([number],btnstatus)

                    return (
                      <>
                        {storeTwoWayPanelButtons?.type === "dimmer_light" &&
                          type === "dimmer_light" && (
                            <div className="block relative overflow-y-auto  max-h-[400px]">
                              <ul className="space-y-2">
                                <div key={number} className=" border-b pb-2">
                                  <li className="mt-5 flex flex-col gap-2">
                                    <div className="flex justify-between items-center">
                                      <span className="text-gray-600 font-medium">
                                        Button {number}
                                      </span>
                                      <div className="flex items-center gap-2">
                                        <button
                                          className=" pl-6 pr-6"
                                          onClick={() =>
                                            handleDropdownMenu(
                                              !isDropdownOpen,
                                              number
                                            )
                                          }
                                        >
                                          <ul className="-space-y-8">
                                            <li>•</li>
                                            <li>•</li>
                                            <li>•</li>
                                          </ul>
                                        </button>
                                        {selectedIndex === number &&
                                          isDropdownOpen && (
                                            <div
                                              ref={dropdownRef}
                                              className="absolute right-4 flex z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-22"
                                            >
                                              <ul className=" text-sm text-gray-700">
                                                <li className="block px-4 py-2 hover:bg-gray-100">
                                                  <button
                                                    onClick={() =>
                                                      handleWithTwoWayBinding(
                                                        viewPanelButtons.panelId,
                                                        number
                                                      )
                                                    }
                                                  >
                                                    With two way binding
                                                  </button>
                                                </li>
                                              </ul>
                                              {loadingStates && <Loader />}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                    {type === "dimmer_light" && (
                                      <RangeSlider
                                        buttonId={number}
                                        macId={viewPanelButtons.panelId}
                                        value={sliderValues[number] || 0}
                                        onChange={(value: number) =>
                                          sliderValueChanged(number, value)
                                        }
                                        handleButtonClick={handleButtonClick}
                                        classes="w-full"
                                      />
                                    )}
                                  </li>
                                </div>
                              </ul>
                            </div>
                          )}
                        {storeTwoWayPanelButtons?.type === "light" &&
                          type === "light" && (
                            <div className="block relative overflow-y-auto  max-h-[400px]">
                              <ul className="space-y-2">
                                <div key={number} className=" border-b pb-2">
                                  <li className="mt-3 flex flex-col gap-2">
                                    <div className="flex justify-between items-center">
                                      <span className="text-gray-600 font-medium">
                                        Button {number}
                                      </span>
                                      <div className="flex items-center gap-2">
                                        <button
                                          className=" pl-6 pr-6"
                                          onClick={() =>
                                            handleDropdownMenu(
                                              !isDropdownOpen,
                                              number
                                            )
                                          }
                                        >
                                          <ul className="-space-y-8">
                                            <li>•</li>
                                            <li>•</li>
                                            <li>•</li>
                                          </ul>
                                        </button>
                                        {selectedIndex === number &&
                                          isDropdownOpen && (
                                            <div
                                              ref={dropdownRef}
                                              className="absolute right-4 flex z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-22"
                                            >
                                              <ul className=" text-sm text-gray-700">
                                                <li className="block px-4 py-2 hover:bg-gray-100">
                                                  <button
                                                    onClick={() =>
                                                      handleWithTwoWayBinding(
                                                        viewPanelButtons.panelId,
                                                        number
                                                      )
                                                    }
                                                  >
                                                    With two way binding
                                                  </button>
                                                </li>
                                              </ul>
                                              {loadingStates && <Loader />}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              </ul>
                            </div>
                          )}
                      </>
                    );
                  })}
                </div>
              </div>
            ) : (
              <Loader position="absolute top-[50%]  -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" />
            )}
            <button
              className="absolute top-1 right-2 text-red-500 font-bold text-lg"
              onClick={() => handleTwoWayBindingDataClose()}
            >
              ×
            </button>
          </div>
        </div>
      )}

      <div className="flex flex-row gap-4 p-4">
        <h1 className="text-3xl">
          Start Config [{isConnected ? "Connected" : "Not Connected"}]
        </h1>
        {/* <button onClick={async () => localFunction()}>get-status</button> */}
        <button
          className="px-5 py-2 bg-slate-400 text-white font-bold rounded-lg"
          onClick={async () => {
            if (isConnected === true) {
              await serialService.disconnect();
              setIsConnected(false);
            } else {
              serialService.connect(
                (status: boolean | ((prevState: boolean) => boolean)) => {
                  setIsConnected(status);
                  setConfiguredList([]);
                  setParableList([]);
                  getConfiguredPanels();
                },
                (text) => {
                  setLoadingStates(true);
                  const [action, data] = text.split(":");

                  const rowAction = action.trim();
                  const rowData = data
                    ?.replaceAll("<", "")
                    ?.replaceAll(">", "");

                  if (rowAction.includes("PN_")) {
                    const [_, macAddress] = rowAction.split("PN_");
                    setViewPanelButtons((obj) => {
                      if (obj == null) {
                        obj = {
                          panelId: macAddress,
                          buttonCommand: [rowData],
                        };
                      } else if (obj.panelId == macAddress) {
                        if (!obj.buttonCommand.includes(rowData)) {
                          obj.buttonCommand.push(rowData);
                        }
                        // obj.buttonCommand.push(rowData);
                      } else {
                        obj = {
                          panelId: macAddress,
                          buttonCommand: [rowData],
                        };
                      }

                      return obj;
                    });
                  }

                  if (rowAction === "SL") {
                    if (rowData.trim().length === 0) {
                      setParableList([]);
                      return;
                    }

                    const list = rowData
                      .split(",")
                      .map((panel: string) => panel.trim());

                    setParableList(list);
                  } else if (rowAction === "PR") {
                    if (rowData.trim().length === 0) {
                      setConfiguredList([]);
                      return;
                    }

                    const list = rowData
                      .split(",")
                      .map((panel: string) => panel.trim());

                    setConfiguredList(list);
                    // setConfiguredList((prevList) =>
                    //   Array.from(new Set([...prevList, ...list]))
                    // );
                  } else if (rowAction === "PROK") {
                    getConfiguredPanels();
                  } else if (rowAction === "RMOK") {
                    getConfiguredPanels();
                  }
                  setLoadingStates(false);
                }
              );
            }
          }}
        >
          {isConnected ? "Disconnect" : "Connect"}
        </button>
      </div>

      <BodyComponent
        configuredList={configuredList}
        getConfiguredPanels={getConfiguredPanels}
        parableList={parableList}
        isConnected={isConnected}
        commands={commands}
        setCommands={setCommands}
        handleGetInfoPanel={handleGetInfoPanel}
        serialService={serialService}
        connectionLoadingStates={connectionLoadingStates}
      />
    </div>
  );
}

const RangeSlider = ({
  classes,
  label,
  onChange,
  value,
  buttonId,
  macId,
  handleButtonClick,
  ...sliderProps
}: any) => {
  const [sliderVal, setSliderVal] = useState(0);

  useEffect(() => {
    setSliderVal(value);
  }, [value]);

  const changeCallback = (e: any) => {
    const newValue = parseInt(e.target.value, 10);
    setSliderVal(newValue);
  };

  const handleMouseUp = () => {
    const state = sliderVal === 0 ? "off" : "on";
    onChange(sliderVal);
    handleButtonClick(macId, buttonId, sliderVal, state);
  };

  const ticks = Array.from({ length: 6 }, (_, index) => index * 20);

  return (
    <div className="range-slider">
      <p>{label}</p>
      <h4>Value: {sliderVal}</h4>
      <input
        type="range"
        value={sliderVal}
        step={20}
        min={0}
        max={100}
        {...sliderProps}
        className={`slider ${classes}`}
        id="myRange"
        onChange={changeCallback}
        onMouseUp={handleMouseUp}
      />
      <div className="flex justify-between">
        {ticks.map((tick) => (
          <span key={tick} className="text-xs">
            {tick}
          </span>
        ))}
      </div>
    </div>
  );
};

export default App;
