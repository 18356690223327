const ToggleSwitch = ({ isOn, onToggle, disabled }: any) => {
  return (
    <div
      className={`relative w-16 h-8 flex items-center rounded-full cursor-pointer p-1 ${
        isOn ? "bg-green-500" : "bg-gray-400"
      } ${disabled ? "cursor-not-allowed opacity-50" : ""}`}
      onClick={() => !disabled && onToggle(!isOn)}
    >
      <div
        className={`absolute w-6 h-6 bg-white rounded-full shadow-md transform transition-transform ${
          isOn ? "translate-x-8" : "translate-x-0"
        }`}
      />
    </div>
  );
};
export default ToggleSwitch;
