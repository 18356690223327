import { useContext, useState } from "react";
import ToggleSwitch from "./toggleSwitch";
import { SerialServiceContext } from "..";
import Loader from "../constants/loader";

function BodyComponent({
  getConfiguredPanels,
  configuredList,
  parableList,
  isConnected,
  commands,
  setCommands,
  handleGetInfoPanel,
  serialService,
  connectionLoadingStates,
}: {
  getConfiguredPanels: () => void;
  configuredList: string[];
  parableList: string[];
  commands: string[];
  isConnected: boolean;
  setCommands: any;
  handleGetInfoPanel: (panelId: string) => void;
  serialService: any;
  connectionLoadingStates: boolean;
}) {
  const context: any = useContext(SerialServiceContext);

  const [isScanning, setIsScanning] = useState(false);
  const [isCommandShow, setIsCommandShow] = useState(false);

  if (!isConnected) {
    return <div />;
  }
  const handleCommandShow = () => {
    setIsCommandShow(!isCommandShow);
  };

  return (
    <>
      {/* {connectionLoadingStates ? (
        <Loader />
      ) : ( */}
      <div className="flex items-start w-screen p-2">
        <div className="w-[40%]">
          <div className="flex flex-row gap-1">
            <div className="font-semibold text-xl">Connected Panels</div>
            <button
              onClick={() => {
                getConfiguredPanels();
              }}
            >
              Refresh
            </button>
          </div>
          {Array.from(new Set(configuredList)).map((panel) => {
            return (
              <div
                className="flex justify-between ml-3 mr-40 mb-2 flex-row gap-6 items-center my-2"
                key={panel}
              >
                <div>{panel}</div>
                <div className="justify-center">
                  <button
                    className="text-white bg-red-300 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-xs px-2 py-1 ml-4 mr-4 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
                    onClick={async () => {
                      await serialService.write(`rm:<${panel}>`);
                    }}
                  >
                    REMOVE
                  </button>

                  <button
                    className="text-white bg-emerald-300 hover:bg-emerald-800 focus:ring-4 focus:ring-emerald-300 font-medium rounded-lg text-xs px-2 py-1  dark:bg-emerald-600 dark:hover:bg-emerald-700 focus:outline-none dark:focus:ring-emerald-800"
                    onClick={async () => {
                      handleGetInfoPanel(panel);
                      // await serialService.write(`getinfo:<${panel}>`);
                    }}
                  >
                    GETINFO
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-1/2 flex justify-center flex-col">
          <button
            type="button"
            className="text-white bg-blue-700 w-fit hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={async () => {
              if (isScanning) {
                serialService.write("searchoff");
              } else {
                serialService.write("searchon");
              }

              setIsScanning(!isScanning);
            }}
          >
            {isScanning ? "Stop Scanning" : "Start Scanning"}
          </button>
          {parableList.map((panel) => {
            if (panel.trim().length === 0) {
              return <div />;
            }

            return (
              <div
                className="flex flex-row gap-6 items-center my-2"
                key={panel}
              >
                <div>{panel}</div>
                <button
                  className="px-2 py-1 bg-blue-500 text-white font-medium rounded-lg text-xs"
                  onClick={async () => {
                    await serialService.write(`pair:<${panel}>`);

                    // await serialService.writeRead(`add_${panel}`, kBaudRate);
                    // setConfiguredList((list) => {
                    //   return [...list, panel];
                    // });
                    // setParableList((list) => {
                    //   return list.filter((item) => item !== panel);
                    // });
                  }}
                >
                  Pair
                </button>
              </div>
            );
          })}
        </div>
        <div className="w-1/2 p-5">
          <div className="font-bold flex pb-4">
            <div className="font-bold flex items-center ">
              Show Commands :
              <div className="pl-4">
                <ToggleSwitch
                  // buttonId={number}
                  // disabled={loadingStates}
                  // macId={viewPanelButtons.panelId}
                  // sliderValue={sliderValues[number] || 0}
                  isOn={isCommandShow}
                  onToggle={() => handleCommandShow()}
                />
              </div>
            </div>
            <button
              className="px-2 py-1 bg-emerald-300 text-black font-medium rounded-lg text-xs ml-10"
              onClick={() => {
                setCommands([]);
              }}
            >
              Clear
            </button>
          </div>
          {isCommandShow && (
            <div className="max-h-[80vh] overflow-y-auto border rounded-md p-2">
              <div className="font-bold">Commands:</div>

              {isCommandShow &&
                commands.map((command, index) => (
                  <div key={index}>{command}</div>
                ))}
            </div>
          )}
        </div>
      </div>
      {/* )} */}
    </>
  );
}

export default BodyComponent;
